import './App.scss'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React from 'react'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Navbar from './components/Navigation/Navbar'

function App () {
    return (
        <div className="App">
            <Router>
                <Navbar/>
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </Router>
        </div>
    )
}

export default App
