import React from 'react'
import propTypes from 'prop-types'
import { HashLink as Link } from 'react-router-hash-link'

NavItem.propTypes = {
    onClick: propTypes.func,
    text: propTypes.string,
    to: propTypes.string
}

/**
 * @returns navigation button item
 */
export default function NavItem ({ onClick, text, to }) {
    // change link type
    if (to === '/') {
        return <li><Link smooth to={to} className="common-link" onClick={onClick} exact >{text}</Link></li>
    }
    return <li><Link smooth to={to} className="common-link" onClick={onClick}>{text}</Link></li>
}
