import React, { useState, useEffect } from 'react'
import { ChevronsLeft, ChevronsRight } from 'react-feather'
import PropTypes from 'prop-types'

function ImageSlider ({ images, autoplayInterval }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const [autoplay, setAutoplay] = useState(true)

    useEffect(() => {
        let intervalId
        if (autoplay) {
            intervalId = setInterval(() => {
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length)
            }, autoplayInterval)
        }
        return () => clearInterval(intervalId)
    }, [autoplay, images.length, autoplayInterval])

    const handlePrevClick = () => {
        setCurrentImageIndex(prevIndex =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        )
        setAutoplay(false)
    }

    const handleNextClick = () => {
        setCurrentImageIndex(prevIndex =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        )
        setAutoplay(false)
    }

    const handleSpecificClick = value => () => {
        setCurrentImageIndex(
            value > images.length - 1 || value < 0 ? 0 : value
        )
        setAutoplay(false)
    }

    return (
        <div className="image-slider">
            <div className='button-div'>
                <button className="prev" onClick={handlePrevClick}><i className='icon'><ChevronsLeft color='black' className='icon-style'/></i></button>
                <div className='specific-buttons'>
                    <button className='specific-button' onClick={handleSpecificClick(0)}>01</button>
                    <button className='specific-button' onClick={handleSpecificClick(1)}>02</button>
                    <button className='specific-button' onClick={handleSpecificClick(2)}>03</button>
                </div>
                <button className="next" onClick={handleNextClick}><i className='icon'><ChevronsRight color='black' className='icon-style'/></i></button>
            </div>
            <img src={images[currentImageIndex]} alt="" className='animate__animated animate__fadeInRight'/>
        </div>
    )
}

ImageSlider.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    autoplayInterval: PropTypes.number.isRequired
}

export default ImageSlider
