import React from 'react'
import '../../styles/Resume.scss'
import Imageslider from '../Imageslider/Imageslider'
import { resumeImages } from '../Imageslider/images'
import { Download } from 'react-feather'
import 'animate.css'

function Resume () {
    return (
        <div id="resume">
            <div className='resume-container'>

                <div className='side-text'>
                    <h3> 01 </h3>
                    <div className='side-line'/>
                    <h3> RESUME </h3>
                    <div className='side-line-2'/>
                </div>

                <div className='right-container'>
                    <h2>
                        ABOUT ME
                    </h2>
                    <p className="resume-text">
                        About me: I&#39;m a multipurpose junior programmer, living in Ljubljana, Slovenia.
                        I&#39;m currently employed at <a href="https://www.httpool.com/">Httpool</a> as a Backend Developer. Under this paragraph you can see a button to download my resume.
                        On top of that, you can see a recap of my experience down below.
                    </p>
                    <a className="face-button" href="./cv2_maksrogelj.pdf" download="maksrogelj.pdf">
                        <div className='face-primary'>
                            <i className='icon'><Download color='white'/></i>
                             DOWNLOAD
                        </div>
                        <div className='face-secondary'>
                             SIZE: 414 KB
                        </div>
                    </a>
                </div>

                <div className='image-container'>
                    <Imageslider images={resumeImages} autoplayInterval={5000} className='image-slider'/>
                </div>
            </div>
        </div>
    )
}

export default Resume
