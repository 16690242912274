import React from 'react'
import '../../styles/Projects.scss'
import { ShoppingBag, Cpu, UserCheck } from 'react-feather'
import 'animate.css'

function Projects () {
    return (
        <div id="projects">

            <div className='projects-text'>
                <h2>
                    LATEST PROJECTS
                </h2>
            </div>

            <div className='projects-overall'>
                <div className='side-text'/>
                <div className='projects-container'>

                    <div className='card'>
                        <div className='icon'>
                            <ShoppingBag size={70} color={'#ff502f'} className='icon-style' />
                        </div>
                        <h3>Picto.market</h3>
                        <p>Created an online store for a design company called Picto. I used Wordpress and Woocommerce, so the company can easily edit their website.</p>
                        <a href="https://www.picto.market/">
                            LEARN MORE
                        </a>
                    </div>

                    <div className='card'>
                        <div className='icon'>
                            <Cpu size={70} color={'#ff502f'} className='icon-style' />
                        </div>
                        <h3>Httpool platforms</h3>
                        <p>Been working as a backend developer at Httpool since summer of 2022. I use PHP, SQL, Graphql and sometimes React.</p>
                        <a href="https://www.httpool.si/" >
                            LEARN MORE
                        </a>
                    </div>

                    <div className='card'>
                        <div className='icon'>
                            <UserCheck size={70} color={'#ff502f'} className='icon-style' />
                        </div>
                        <h3>Portfolio</h3>
                        <p>Crated a portfolio for myself in December 2022. I used React and Sassy CSS. I created it solely because I love designing and programming new things.</p>
                        <a href="https://www.maksrogelj.eu/">
                            LEARN MORE
                        </a>
                    </div>
                </div>
                <div className='side-text'>
                    <h3> 03 </h3>
                    <div className='side-line'/>
                    <h3> PROJECTS </h3>
                    <div className='side-line-2'/>
                </div>
            </div>

        </div>
    )
}

export default Projects
