import React from 'react'
import Footer from '../components/Footer/Footer'
import HeroSection from '../components/Herosection/Herosection'
import Projects from '../components/Projects/Projects'
import Resume from '../components/Resume/Resume'
import Contactme from '../components/Contactme/Contactme'

function Home () {
    return (
        <>
            <div>
                <HeroSection/>
                <Resume/>
                <Projects/>
                <Contactme/>
                <Footer/>
            </div>
        </>
    )
}

export default Home
