import React from 'react'
import Image from '../../styles/images/contact_me.png'
import '../../styles/Contactme.scss'
import { Clipboard } from 'react-feather'

export default function Contactme () {
    return (
        <div id='contactme' >
            <div className='contactme-container'>

                <div className='side-text'>
                    <h3> 04 </h3>
                    <div className='side-line'/>
                    <h3> CONTACT ME </h3>
                    <div className='side-line-2'/>
                </div>

                <div className='text-container'>
                    <h2>
                        CONTACT ME
                    </h2>
                    <p>If you want to contact me, please write me an mail, or text me on Linkedin, Github or Instagram.</p>
                    <p>
                        <b> Mail: </b> info@maksrogelj.eu

                        <button onClick={() => { navigator.clipboard.writeText('info@maksrogelj.eu') }}>
                            <Clipboard color={'#454852'} className='icon-style' />
                            <b>copy to clipboard</b>
                        </button>
                    </p>
                    <p>
                        <b> Github: </b> AnonymousGrizzly

                        <button onClick={() => { navigator.clipboard.writeText('https://github.com/AnonymousGrizzly') }}>
                            <Clipboard color={'#454852'} className='icon-style' />
                            <b>copy to clipboard</b>
                        </button>
                    </p>
                    <p>
                        <b> Linkedin :</b> Maks Rogelj

                        <button onClick={() => { navigator.clipboard.writeText('https://www.linkedin.com/in/maks-rogelj-6ba856242/') }}>
                            <Clipboard color={'#454852'} className='icon-style' />
                            <b>copy to clipboard</b>
                        </button>
                    </p>
                </div>
                <div className='red-right-chevron'/>
            </div>
            <img className='contactme-image' src={Image} alt=''/>
        </div>
    )
}
