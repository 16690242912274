import React from 'react'
import '../../styles/HeroSection.scss'
import Image from '../../styles/images/grey_profile.png'
import 'animate.css'

function Herosection () {
    return (
        <div id="home">
            <div className='text-wrapper'>
                <p>JUNIOR DEVELOPER  &amp; UI/UX DESIGNER </p>
                <h1>I&#39;m Maks <br/>Rogelj.</h1>
                <p>Scroll down</p>
            </div>
            <div className='red-block animate__animated animate__fadeInRight'/>
            <img src={Image} alt="" id="herosection-image" className='animate__animated animate__fadeInUp animate__delay-1s'/>
        </div>
    )
}

export default Herosection
