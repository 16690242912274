import React from 'react'
import '../styles/NotFound.scss'

function NotFound () {
    return (
        <div id="notfound">
            <h2>Woah, where do you think you are going??</h2>
        </div>
    )
}

export default NotFound
