import React, { useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import '../../styles/Navbar.scss'
import NavItem from './NavItem'
import { Menu, X } from 'react-feather'
import 'animate.css'

function Navbar () {
    const [click, setClick] = useState(false) // click za mobile menu
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    return (
        <nav id="navbar" className='animate__animated animate__fadeIn animate__delay-1s'>
            <Link smooth to="/" className="navbar-logo" onClick={closeMobileMenu}>
                {' '}
          Maks Rogelj
                {' '}
            </Link>
            <div className="menu-icon" onClick={handleClick} >
                {click ? (<X size="35"/>) : (<Menu size="35"/>)}
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <NavItem
                    text="HOME"
                    to="#home"
                    onClick={closeMobileMenu}
                />
                <NavItem
                    text="RESUME"
                    to="#resume"
                    onClick={closeMobileMenu}
                />
                <NavItem
                    text="PROJECTS"
                    to="#projects"
                    onClick={closeMobileMenu}
                />
                <NavItem
                    text="CONTACT ME"
                    to="#contactme"
                    onClick={closeMobileMenu}
                />
            </ul>
        </nav>
    )
}

export default Navbar
