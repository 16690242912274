import React from 'react'
import '../../styles/Footer.scss'
import { GitHub, Instagram, Linkedin } from 'react-feather'

function Footer () {
    return (
        <div id='footer'>
            <div>
                <p> Copyright © 2023 Not All Rights Reserved </p>
            </div>
            <div>
                <p> Maks Rogelj </p>
            </div>
            <div className='footer-right'>
                <a href="https://github.com/AnonymousGrizzly"><GitHub color='white'/></a>
                <a href="https://www.instagram.com/anonymousgrizzly/"><Instagram color='white'/></a>
                <a href="https://www.linkedin.com/in/maks-rogelj-6ba856242/"><Linkedin color='white'/></a>
            </div>
        </div>
    )
}

export default Footer
